import { httpOrigin } from '~configs/apiOrigin';
import logger from '~utils/logger';
import { apiVersion } from '~utils/platform';

type UserSettingsResponse = string;

const getUserSettings = async (): Promise<UserSettingsResponse | null> => {
  const origin = httpOrigin(
    `/trader-rest-service/${apiVersion()}/user-settings`
  );

  try {
    const response = await fetch(origin, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `${sessionStorage.getItem('token')}`,
      },
    });
    const data = await response.json();

    if (!response.ok && data.status !== 200) {
      throw new Error('user settings api call failed');
    }

    return data;
  } catch (err) {
    logger.logCriticalError(err);
    return null;
  }
};

export default getUserSettings;
