import React, { lazy, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { getIsAuth0 } from '~utils/platform';
import Loading from '~components/common/loading';
import {
  SET_AUTHENTICATION_TOKEN,
  USER_DETAILS,
  USER_PERMISSIONS,
} from '~actions/action_types';
import RequireClassicAuth from './components/auth/RequireClassicAuth';
import { Dashboard, NotFound, WidgetPopout } from './pages';
import SignIn from './pages/Login';
import getUserConfig from './callers/getUserConfig';

const Desktop = lazy(() => import('./pages/desktop'));

const HOC = () => (
  <RequireClassicAuth>
    <Dashboard />
  </RequireClassicAuth>
);

const routes = (
  <Router>
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/desktop" component={Desktop} />
      <Route path="/dashboard" component={HOC} />
      <Route path="/widget" component={HOC} />
      <Route path="/widget-popout" component={WidgetPopout} />
      <Route component={NotFound} />
    </Switch>
  </Router>
);

const Auth0Routes = () => {
  const dispatch = useDispatch();
  const {
    getAccessTokenSilently,
    loginWithRedirect,
    isAuthenticated,
    isLoading,
  } = useAuth0();

  const urlParams = new URLSearchParams(window.location.search);
  const invitation = urlParams.get('invitation');
  const organization = urlParams.get('organization');

  const setUserConfig = useCallback(async () => {
    const token = await getAccessTokenSilently();
    sessionStorage.setItem('token', `Bearer ${token}`);

    const response = await getUserConfig();

    const {
      username,
      id_companies: idCompany,
      company_name: companyName,
      id_users: userId,
      rate_engine_enabled: rateEngineEnabled,
      shadow_admin: shadowAdmin,
      is_slave: isViewer,
      is_enable_websocket_routing: isEnableWebsocketRouting = false,
      permissions,
    } = response.user;

    sessionStorage.setItem('global_username', username);
    sessionStorage.setItem('global_org_id', idCompany);
    sessionStorage.setItem('AuthIToken', token);
    sessionStorage.setItem('global_organization', companyName);

    dispatch({
      type: USER_PERMISSIONS,
      payload: permissions,
    });

    dispatch({
      type: USER_DETAILS,
      payload: {
        username,
        userId,
        organization: companyName,
        organizationId: idCompany,
        rateEngineEnabled,
        shadowAdmin,
        isViewer,
        isEnableWebsocketRouting,
      },
    });

    dispatch({ type: SET_AUTHENTICATION_TOKEN, payload: token }); // it's a valid token. Set to the redux store
  }, [getAccessTokenSilently, dispatch]);

  useEffect(() => {
    if (!isLoading) {
      if (invitation && organization) {
        loginWithRedirect({
          authorizationParams: {
            organization,
            invitation,
          },
        });
      } else if (!isAuthenticated) {
        loginWithRedirect();
      } else {
        setUserConfig();
      }
    }
  }, [
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    invitation,
    organization,
    setUserConfig,
  ]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          {isAuthenticated ? (
            <Redirect
              to={{
                pathname: '/dashboard',
                state: { from: '/' },
              }}
            />
          ) : null}
        </Route>
        <Route path="/desktop" component={Desktop} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/widget" component={Dashboard} />
        <Route path="/widget-popout" component={WidgetPopout} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

export default getIsAuth0() ? <Auth0Routes /> : routes;
