import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import {
  SEND_SETTINGS,
  SET_SETTINGS,
  SEND_INSTRUMENT_SETTINGS,
  SET_CURRENT_DATE,
  REMOVE_SETTINGS,
} from '../actions/action_types';
import {
  sendSetting,
  explodeSetting,
  getDefaultSettings,
} from '../utils/settings';
import { renameKeys, deepMerge } from '../utils';
import { getHost } from '../utils/platform';
import { layoutDomains } from '../constants';

const localSettings = {};

const IsValidJSONString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    console.log(`With inValid JSON ***   ${str}   ***    error => ${e} `);
    return false;
  }
  return JSON.parse(str);
};

// Make this able to read old properties!
// map only current user settings to redux store after login
if (sessionStorage.getItem('token')) {
  const currentUserName = sessionStorage.getItem('global_username');
  const keyStartAt = `_${currentUserName}_`;
  const settingsFromLS = Object.entries(localStorage);

  for (let i = 0; i < settingsFromLS.length; i += 1) {
    const [key, settings] = settingsFromLS[i];

    if (key.indexOf(keyStartAt) === 0) {
      const [, domain] = key.split(keyStartAt);
      if (domain === 'global_settings') {
        // This are root on redux, that's why we do this
        const globalSettings = Object.entries(IsValidJSONString(settings));
        for (let j = 0; j < globalSettings.length; j += 1) {
          const [gKey, gSettings] = globalSettings[j];
          localSettings[gKey] = gSettings;
        }
      } else if (domain === (domain.match(/\w+-\w+\.+.+/) || {}).input) {
        localSettings.instruments = deepMerge(localSettings.instruments, {
          [domain]: IsValidJSONString(settings),
        });
      } else {
        localSettings[domain] = IsValidJSONString(settings);
      }
    }
  }
}

export default function settingsReducer(
  state = deepMerge(getDefaultSettings(), localSettings),
  action
) {
  const { type, payload = {} } = action;
  if (!payload) return state;
  const appName = window.APP_NAME || getHost().APP_NAME;
  const username = sessionStorage.getItem('global_username');
  let { domain = '', instrument = '', settingsObj = {}, identifier } = payload;

  switch (type) {
    case SEND_INSTRUMENT_SETTINGS: // Step 1: Transform instrument settings
      const keyMap = Object.assign(
        ...Object.keys(settingsObj).map((fieldKey) => ({
          [fieldKey]: `${domain}.${fieldKey}`,
        }))
      );
      settingsObj = renameKeys(settingsObj, keyMap);
      domain = instrument;

    case SEND_SETTINGS: // Step 2: Do recursive or single calls to localStorage / microService
      let explodedSettingsObj = {};
      for (let [key, value] of Object.entries(settingsObj)) {
        sendSetting(key, value, domain);
        explodedSettingsObj = Object.assign(
          {},
          explodedSettingsObj,
          explodeSetting(key, value)
        );
      }
      settingsObj = explodedSettingsObj;

    case SET_SETTINGS: // Step 3: Transform to local response and edit localStore
      let newSettings = {};
      localStorage.setItem(
        `_${username}_${domain}`,
        JSON.stringify(
          layoutDomains.includes(domain)
            ? settingsObj[appName]
            : deepMerge(
                IsValidJSONString(
                  localStorage.getItem(`_${username}_${domain}`)
                ),
                settingsObj
              )
        )
      );
      switch (domain) {
        case 'global_settings':
          newSettings = settingsObj;
          break;
        case 'golden_layout':
        case 'flex_layout':
          newSettings = {
            [domain]: settingsObj[appName]
              ? settingsObj[appName]
              : settingsObj.default,
          };
          break;
        case 'blotter_widget':
        case 'instrument_trading_widget':
        case 'instruments_widget':
        case 'multicurrency_widget':
        case 'rfs_widget':
        case 'ladder_widget':
        case 'pnl_widget':
        case 'client_pricing_widget':
          newSettings = { [domain]: deepMerge(state[domain], settingsObj) };
          break;
        case 'instrument':
        case (domain.match(/\w+-\w+\.+.+/) || {}).input:
          domain =
            domain === 'instrument' ? 'default_instrument_settings' : domain;

          const newInstruments = state.instruments
            ? deepMerge(state.instruments, {
                [domain]: settingsObj,
              })
            : { [domain]: settingsObj };

          newSettings = { instruments: newInstruments };
          break;
        default:
          const updatedInstruments = state.instruments
            ? deepMerge(state.instruments, {
                [domain]: settingsObj,
              })
            : { [domain]: settingsObj };
          newSettings = { instruments: updatedInstruments };
          break;
      }

      return Object.assign({}, state, newSettings);

    case SET_CURRENT_DATE: {
      return {
        ...state,
        currentDate: action.payload,
      };
    }
    case REMOVE_SETTINGS: {
      const cleanSetting = {};
      const newIdentifier = Array.isArray(identifier)
        ? identifier
        : [identifier];

      // if it's not array, then widget might be customized
      if (
        ['rfs_widget', 'multicurrency_widget'].includes(domain) &&
        !Array.isArray(cleanSetting.boxes)
      ) {
        cleanSetting.boxes = omit(state[domain].boxes, newIdentifier);

        // if identifier is found, then widget has been customized
        if (
          !isEqual(
            Object.keys(cleanSetting.boxes),
            Object.keys(state[domain].boxes)
          )
        ) {
          setTimeout(sendSetting('boxes', cleanSetting.boxes, domain));
        }
      }

      // if it's not empty, then widget might be customized
      if (
        [
          'pnl_widget',
          'client_pricing_widget',
          'rfq_widget',
          'rfq_blotter_widget',
        ].includes(domain) &&
        !isEmpty(state[domain].sub_widgets)
      ) {
        cleanSetting.sub_widgets = omit(
          state[domain].sub_widgets,
          newIdentifier
        );

        // if identifier is found, then widget has been customized
        if (
          !isEqual(
            Object.keys(cleanSetting.sub_widgets),
            Object.keys(state[domain].sub_widgets)
          )
        ) {
          setTimeout(
            sendSetting('sub_widgets', cleanSetting.sub_widgets, domain)
          );
        }
      }

      // if it's not empty, then widget might be customized
      if (domain === 'ladder_widget' && !isEmpty(state[domain].widgets)) {
        cleanSetting.widgets = omit(state[domain].widgets, newIdentifier);

        // if identifier is found, then widget has been customized
        if (
          !isEqual(
            Object.keys(cleanSetting.widgets),
            Object.keys(state[domain].widgets)
          )
        ) {
          setTimeout(sendSetting('widgets', cleanSetting.widgets, domain));
        }
      }

      localStorage?.setItem(
        `_${username}_${domain}`,
        JSON.stringify({ ...state[domain], ...cleanSetting })
      );

      return { ...state, [domain]: { ...state[domain], ...cleanSetting } };
    }
    default:
      return state;
  }
}
